import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { getAllEmployee, getEmployeeReport } from "../../../actions/employeeAction";
import { createEmployeePaySlip } from "../../../actions/PaySlipAction";
import moment from "moment";

import { Layout, Breadcrumb, Row, Col, Select, Divider, DatePicker, Button, InputNumber } from "antd";
const { Content } = Layout;
const { Option } = Select;

const AddPayslip = ({ getAllEmployee, employeeList, getEmployeeReport, createEmployeePaySlip }) => {
    const [updatelist, setUpdatelist] = useState(false);
    const [data, setData] = useState();
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const selectedEmployee = useRef(null)
    const DaysInMonth = useRef("")
    const Month = useRef(1)
    const Year = useRef(2023)
    const NoOfHolidays = useRef(0)
    const NoOfWokingDays = useRef(0)
    const Overtime = useRef("")
    const Extraovertime = useRef(0)
    const holidayAllowance = useRef(0)
    const holidayattendance = useRef(0)
    const attendancebouns = useRef(0)

    // useEffect(() => {
    //     getAllEmployee();
    // }, []);

    useEffect(() => {
        if (selectedEmployee.current != null && DaysInMonth.current != "") {
            setLoading(true);
            getEmployeeReport(selectedEmployee.current, Month.current, Year.current, DaysInMonth.current, NoOfHolidays.current, NoOfWokingDays.current, Overtime.current, Extraovertime.current, holidayAllowance.current, holidayattendance.current, attendancebouns.current).then(data => {
                console.log(data);
                setData(data)
                setLoading(false);
            });
        }
    }, [reload]);

    const onFinish = (method, heldup) => {
        console.log(data.employee)
        console.log(JSON.stringify(data.employee))
        const formData = new FormData();
        formData.append("employee", data.employee.id);
        formData.append("salary", data.salary.id);
        formData.append("salaryMonth", Month.current);
        formData.append("salaryYear", Year.current);
        formData.append("publicHoliday", NoOfHolidays.current);
        formData.append("payment_method_info_1", method);
        formData.append("heldup", heldup);
        formData.append("amount_1", data.summaryData.totalsalary);
        formData.append("employeeData", JSON.stringify(data.employee));
        formData.append("attendanceData", JSON.stringify(data.attendance));
        formData.append("leaveData", JSON.stringify(data.leave));
        formData.append("salaryData", JSON.stringify(data.salary));
        formData.append("summaryData", JSON.stringify(data.summaryData));
        createEmployeePaySlip(formData).then(e => {
            alert("Paid")
        })

    }

    var days = function (month, year) {
        return new Date(year, month, 0).getDate();
    };

    const onMonthChange = (value, dateString) => {
        const myArr = dateString.split("-");
        let year = myArr[0];
        let mnth = myArr[1];
        Month.current = mnth
        Year.current = year
        DaysInMonth.current = days(mnth, year)
    };
    const onFilter = () => {
        if (selectedEmployee.current == null) {
            alert("Please select an Employee")
            return
        }
        if (DaysInMonth.current == "") {
            alert("Please select a month")
            return
        }
        setReload(!reload)
    }

    return (
        <>
            <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>PaySlip</Breadcrumb.Item>
                <Breadcrumb.Item>Add</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-background main-frame">
                <Row wrap={true}>
                    <Col flex="auto">
                        EPIN :
                        <InputNumber
                            placeholder="Employee PIN"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                selectedEmployee.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Month :
                        <DatePicker
                            picker="month"
                            style={{ width: "100%", minWidth: "140px" }}
                            // defaultValue={moment()}
                            onChange={onMonthChange}
                            bordered={true}
                        />
                    </Col>
                    <Col flex="auto">
                        No of Holidays :
                        <InputNumber
                            placeholder="No of Holidays"
                            style={{ width: "100%", minWidth: "40px" }}
                            onChange={(value) => {
                                NoOfHolidays.current = value;
                            }}
                            // defaultValue={0}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Manual Overtime :
                        <InputNumber
                            placeholder="Manual Overtime"
                            style={{ width: "100%", minWidth: "40px" }}
                            onChange={(value) => {
                                if (value !== null) {
                                    Overtime.current = value;
                                } else {
                                    Overtime.current = "";
                                }
                            }}
                            // defaultValue={0}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        .
                        <Button style={{ width: "100%", minWidth: "140px" }} type="primary" onClick={onFilter}>
                            Filter
                        </Button>
                    </Col>
                </Row>
                <Divider />
                {/* <Row wrap={false}>
                    <Col flex="auto">
                        Overtime :
                        <InputNumber
                            placeholder="Overtime"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                Overtime.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Extra overtime :
                        <InputNumber
                            placeholder="Extra overtime"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                Extraovertime.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Holiday Allowance :
                        <InputNumber
                            placeholder="Holiday Allowance"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                holidayAllowance.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Holiday Attendance :
                        <InputNumber
                            placeholder="Holiday Attendance"
                            style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                holidayattendance.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        Attendance bonus:
                        <InputNumber
                            placeholder="Attendance bonus"
                            // style={{ width: "100%", minWidth: "140px" }}
                            onChange={(value) => {
                                attendancebouns.current = value;
                            }}
                            min={0}
                        />
                    </Col>
                    <Col flex="auto">
                        .
                        <Button style={{ width: "100%", minWidth: "140px" }} type="primary" onClick={onFilter}>
                            Filter
                        </Button>
                    </Col>
                </Row> */}
                {data ?
                    (data.error == 0) ?
                        <>
                            <ReactToPrint
                                trigger={() => <button>Print this out!</button>}
                                content={() => componentRef.current}
                            />
                            <Divider />
                            <div ref={componentRef} style={{ padding: "10px", border: "1px solid gray" }}>
                                <Row >
                                    <Col span={24}><h3>Famous Design LTD.</h3>
                                        <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
                                        <p>বেতন ও অতিরিক্ত কাজের সময় রশিদ </p>
                                        <p>মাসের নাম ও বছর : {moment(Year.current + "-" + Month.current + "-1").format("MMMM,  YYYY")}</p>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>কার্ড নং</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.employee_pin}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>নাম</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>পদবি</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.user_roleName}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বিভাগ</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.employeeDepartment}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>যোগদান</td>
                                                <td style={{ paddingLeft: "10px" }}>: {moment(data.employee.joining_date).format("DD-MM-YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বেতন বৃদ্ধি</td>
                                                <td style={{ paddingLeft: "10px" }}></td>
                                            </tr>

                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>

                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মাসের দিন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.totaldays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>কাজের দিন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.workingdays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>সাপ্তাহিক ছুটি</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.Holidays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>উপস্থিত</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.present}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অনুপস্থিত</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.absent}</td>
                                            </tr>


                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    ছুটির কারণ<br />
                                                    {data.leave.map(item => {
                                                        return <>{item.leaveType__Typename}<br /></>
                                                    })}
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <br />
                                                    {data.leave.map(item => {
                                                        return <>{item.total}<br /></>
                                                    })}
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মূল বেতন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.monthlySalary}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বাড়ি ভাড়া </td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.houseRent}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>চিকিৎসা ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.medAllowance}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>খাবার ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.foodAllowance}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অন্যান্য ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.convenience}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মোট বেতন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.grossSalary}</td>
                                            </tr>


                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অতিরিক্ত কাজের সময় মোট ঘণ্টা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.overtime}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অতিরিক্ত কাজের সময় রেন্ট / ঘণ্টা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.overtime}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মোট পরিশোধ যোগ্য টাকার পরিমাণ</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.overtimepayment}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>উপস্থিত বোনাস</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.bonus}</td>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    অনুপস্থিত কর্তন
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.absentpayment}
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table >
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    মোট বেতন (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.salary.grossSalary}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    মোট পরিশোধ যোগ্য টাকার পরিমাণ (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.overtimepayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    উপস্থিত বোনাস (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.bonus}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    অনুপস্থিত কর্তন (-)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.absentpayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    সর্বমোট
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.summaryData.totalsalary}
                                                </td>
                                            </tr>

                                        </table>
                                    </Col>

                                </Row>

                                <Divider />
                                <Divider />

                                <Row >
                                    <Col span={24}><h3>Famous Design LTD.</h3>
                                        <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
                                        <p>বেতন ও অতিরিক্ত কাজের সময় রশিদ </p>
                                        <p>মাসের নাম ও বছর : {moment(Year.current + "-" + Month.current + "-1").format("MMMM,  YYYY")}</p>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>কার্ড নং</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.employee_pin}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>নাম</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>পদবি</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.user_roleName}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বিভাগ</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.employee.employeeDepartment}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>যোগদান</td>
                                                <td style={{ paddingLeft: "10px" }}>: {moment(data.employee.joining_date).format("DD-MM-YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বেতন বৃদ্ধি</td>
                                                <td style={{ paddingLeft: "10px" }}></td>
                                            </tr>

                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>

                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মাসের দিন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.totaldays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>কাজের দিন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.workingdays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>সাপ্তাহিক ছুটি</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.Holidays}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>উপস্থিত</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.present}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অনুপস্থিত</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.absent}</td>
                                            </tr>


                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    ছুটির কারণ<br />
                                                    {data.leave.map(item => {
                                                        return <>{item.leaveType__Typename}<br /></>
                                                    })}
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    <br />
                                                    {data.leave.map(item => {
                                                        return <>{item.total}<br /></>
                                                    })}
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মূল বেতন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.monthlySalary}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>বাড়ি ভাড়া </td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.houseRent}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>চিকিৎসা ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.medAllowance}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>খাবার ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.foodAllowance}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অন্যান্য ভাতা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.convenience}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মোট বেতন</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.grossSalary}</td>
                                            </tr>


                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অতিরিক্ত কাজের সময় মোট ঘণ্টা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.overtime}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>অতিরিক্ত কাজের সময় রেন্ট / ঘণ্টা</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.salary.overtime}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>মোট পরিশোধ যোগ্য টাকার পরিমাণ</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.overtimepayment}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>উপস্থিত বোনাস</td>
                                                <td style={{ paddingLeft: "10px" }}>: {data.attendance.bonus}</td>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col flex="auto">
                                        <table>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    অনুপস্থিত কর্তন
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.absentpayment}
                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row wrap={false}>
                                    <Col flex="auto">
                                        <table >
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    মোট বেতন (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.salary.grossSalary}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    মোট পরিশোধ যোগ্য টাকার পরিমাণ (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.overtimepayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    উপস্থিত বোনাস (+)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.bonus}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    অনুপস্থিত কর্তন (-)
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.attendance.absentpayment}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ paddingRight: "10px" }}>
                                                    সর্বমোট
                                                </td>
                                                <td style={{ paddingLeft: "10px" }}>
                                                    : {data.summaryData.totalsalary}
                                                </td>
                                            </tr>

                                        </table>
                                    </Col>

                                </Row>
                            </div>
                            <Divider />
                            {data.summaryData.paid ? <><h3>Already Paid</h3><Button type="primary" onClick={() => { onFinish("Bank", false) }}>Resubmit with bank</Button><Button type="primary" onClick={() => { onFinish("Cash", false) }}>Resubmit with cash</Button><Button type="primary" onClick={() => { onFinish("", true) }}>Resubmit with heldup</Button></> : <><Button type="primary" onClick={() => { onFinish("Bank", false) }}>Bank Pay</Button> <Button type="primary" onClick={() => { onFinish("Cash", false) }}>Cash Pay</Button> <Button type="primary" onClick={() => { onFinish("", true) }}>Heldup</Button></>}

                        </>
                        :
                        <>
                            <h1 style={{ marginTop: "20px", color: "red" }}>{data.message}</h1>
                        </>

                    :
                    ""
                }

            </div >
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        employeeList: state.employee.employeelist,
    };
};

export default connect(mapStateToProps, { getAllEmployee, getEmployeeReport, createEmployeePaySlip })(AddPayslip);
